
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'RadioButton',
	components: {},
})
export default class RadioButton extends Vue {
	@Prop() checked!: boolean;
}
