
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RadioButton from './radio-button.component.vue';

@Options({
	name: 'RadioButtonGroup',
	components: { RadioButton },
	emits: ['update:modelValue'],
})
export default class RadioButtonGroup extends Vue {
	@Prop({ default: [] }) options!: { label: string; value: unknown }[];
	@Prop() modelValue!: unknown;
}
